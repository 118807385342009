import {getScheduledFunction} from "util/schedule/schedulerHelpers";

export type OpenModalParamsType = {
    modalId: string,
    modalGroup?: string,
    modalDelay?: number,
    modalIntervalSettings: OpenModalIntervalSettingsType,
    allowedToOpen?: () => boolean,
    onOpen?: () => void
}

export type OpenModalIntervalSettingsType = {
    showDelay?: number,
    showInterval: number,
    showsLimit: number,
    showsLimitResetDelay?: number,
}

// TODO: desplay size check method + url params check method

export const getOpenModalMethod: (props: OpenModalParamsType) => () => void = (props: OpenModalParamsType) => {
    const {
        modalId,
        modalGroup,
        modalDelay,
        modalIntervalSettings: {
            showDelay = 0,
            showInterval,
            showsLimit,
            showsLimitResetDelay = 0,
        },
        allowedToOpen,
        onOpen
    } = props

    return getScheduledFunction({
        prefix: "shown-modal-",
        metaPrefix: "modal-meta-",
        schedulerGroup: modalGroup || modalId,
        callback: () => {
            try {
                if (typeof window !== "undefined") {
                    //console.log("Hit")
                    const myModalEl = window.document.getElementById(modalId);
                    const modal = window.bootstrap.Modal.getOrCreateInstance(myModalEl);
                    modal.show();
                }
            } catch (e) {
                console.error(e)
            }
        },
        intervalSettings: {
            batchSizeLimit: showsLimit,
            batchSizeLimitResetDelay: showsLimitResetDelay,
            executionDelay: modalDelay,
            firstExecutionDelay: showDelay,
            executionsInterval: showInterval
        },
        isExecutionAllowed: () => {
            let cond = false
            try {
                cond = document.querySelectorAll(".modal.show").length <= 0
            } catch (e) {}
            if (allowedToOpen) {
                return cond && allowedToOpen()
            } else {
                return cond
            }
        },
        onSuccess: onOpen,
    })
}
