/*
SchedulerIntervalSettings Explanation

Execution: Function call.
Batch: series of executions in short period.


                    `batchSizeLimit` - number of executions for each batch. In this example - 3.
      ______________|_____________                            `executionDelay` - Time before each execution.
     ↓                            ↓                           ↓
[....(---e)......(---e)......(---e)] _ _ _ _ _ _ _ _ _ [....(---e)......(---e)......(---e)] _ _ _ _ _ _ _ _ _
  ↑                     ↑                        ↑
  |                     |                        `batchSizeLimitResetDelay` - Time that needed to pass between batches.
  |                     `executionsInterval`. Time that needed to pass between executions.
  `firstExecutionDelay` - Delay before first execution.


*/


export type SchedulerMeta = {
    initTime?: number,
    executedTimes?: number,
}

export type SchedulerIntervalSettings = {
    firstExecutionDelay?: number,
    executionDelay?: number,
    executionsInterval: number,
    batchSizeLimitResetDelay?: number,
    batchSizeLimit: number,
}

export type SchedulerParams = {
    schedulerGroup?: string,
    prefix?: string,
    metaPrefix?: string,
    callback: () => void,
    intervalSettings: SchedulerIntervalSettings,
    isExecutionAllowed?: () => boolean,
    onSuccess?: () => void
}

export type SchedulerFactory = (props: SchedulerParams) => () => void

export const getScheduledFunction: SchedulerFactory = (props: SchedulerParams) => {
    const {
        schedulerGroup,
        prefix = "exec-",
        metaPrefix = "exec-meta-",
        callback,
        intervalSettings: {
            firstExecutionDelay = 0,
            executionDelay = 0,
            executionsInterval,
            batchSizeLimitResetDelay = 0,
            batchSizeLimit,
        },
        isExecutionAllowed,
        onSuccess
    } = props

    return () => {
        try {
            const executeOnce = batchSizeLimit == 1 && !batchSizeLimitResetDelay;
            const schedulerIdentity = schedulerGroup
            const schedulerMeta: SchedulerMeta = JSON.parse(String(localStorage.getItem(metaPrefix + schedulerIdentity))) || {}
            const saveSchedulerMeta = () => {
                localStorage.setItem(metaPrefix + schedulerIdentity, JSON.stringify(schedulerMeta))
            }

            let canExecute = true
            const now = Date.now()
            const init = schedulerMeta?.initTime || 0
            let executedTimes = schedulerMeta?.executedTimes || 0

            const execute = () => {
                if(isExecutionAllowed && isExecutionAllowed() || !isExecutionAllowed) {
                    schedulerMeta.initTime = now
                    schedulerMeta.executedTimes = executedTimes + 1
                    saveSchedulerMeta()
                    callback()

                    try {
                        if (onSuccess) onSuccess()
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
            if (executeOnce && (localStorage.getItem(prefix + schedulerIdentity) || executedTimes > 0)) {
                return;
            }

            if (isExecutionAllowed && !isExecutionAllowed()) {
                return;
            }

            // Executions limit handle
            if (batchSizeLimit > 0 && executedTimes >= batchSizeLimit) {
                if (batchSizeLimitResetDelay && (init + batchSizeLimitResetDelay < now)) {
                    executedTimes = 0
                    schedulerMeta.initTime = now
                } else {
                    return;
                }
            }

            // Adding delay before first open
            if (!init && firstExecutionDelay && firstExecutionDelay > 0) {
                schedulerMeta.initTime = now
                saveSchedulerMeta()
                return;
            }

            // Handling first delay
            if (init && executedTimes == 0) {
                if (init + firstExecutionDelay > now) {
                    return;
                }
            }

            // Executing after interval
            if (init && (init + executionsInterval < now)) {
                schedulerMeta.initTime = now
            } else if (init && !(init + executionsInterval < now)) (
                canExecute = false
            )

            if (canExecute) {
                setTimeout(execute, executionDelay)
            }

        } catch (e) {
            console.error(e)
        }
    }
}