export const reportRouteChange = ({ location, prevLocation, pageTransitionDelay }, pluginOptions = {}) => {
    const dataLayer = window?.dataLayer ? window.dataLayer : []

    const sessionStorage = window.sessionStorage ? window.sessionStorage : {}
    const oldPrevLocation = sessionStorage.gatsbyPrevLocation
    sessionStorage.gatsbyPrevLocation = sessionStorage.gatsbyCurrLocation

    // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
    // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
    const sendPageView = () => {

        const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined

        sessionStorage.gatsbyCurrLocation = pagePath
        if (!prevLocation && sessionStorage.gatsbyPrevLocation === sessionStorage.gatsbyCurrLocation) {
            sessionStorage.gatsbyPrevLocation = oldPrevLocation
        }

        const prevPagePath = (prevLocation
            ? prevLocation.pathname + prevLocation.search + prevLocation.hash
            : undefined) || sessionStorage.gatsbyPrevLocation || document.referrer || ""


        dataLayer.push({'event':'gatsby_route_change', 'gatsbyCurrLocation':pagePath, 'gatsbyPrevLocation':prevPagePath})
    }

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(32, pageTransitionDelay || 0)
    setTimeout(sendPageView, delay)

    return null
}
