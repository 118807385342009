import {copyTextToClipboard} from "../src/components/organisms/DownloadUniversal/DownloadOSItem/utils";

export const generateCopyButtons = () => {
    const codeBoxes = document.querySelectorAll("pre[class^=language-]:not([data-disable-code-copy])")

    for (const box of codeBoxes) {
        const code = box.querySelector("code")
        if(code) {
            const button = document.createElement("button");
            button.className = "code-copy-button"
            button.innerHTML = 'Copy';
            button.addEventListener("click", () => {
                button.innerHTML = 'Copied!';
                setTimeout(() => {
                    button.innerHTML = 'Copy'
                }, 1000);
                copyTextToClipboard(code.textContent)
                const copyEvent = new Event("copy", {
                    bubbles: true
                });
                code.dispatchEvent(copyEvent);
            })
            box.append(button)
        }
    }
}
