export const sendGaEvent = (event: any) => {
    try {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(event);
    } catch(e) {}
}

export const getSendGaEventFunction = (event: any) => {
    return () => {sendGaEvent(event)}
}

export const sendLeadInfoJDKPerfEvent = () => {
    if (typeof window !== 'undefined') {
        try {
            (function(l: any,e: any,a: any,d: any,i: any,n: any,f?:any,o?:any)
            {if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
                l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
                l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
            }(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','LI-66D164F76F51E'));
        } catch (e) {}
    }
}