import {getOpenModalMethod} from "util/modals/modalHelpers";
import {getFlag} from "src/components/organisms/DownloadUniversal/Utils/utils";
import {sendGaEvent} from "util/ga/gaHelpers";

const intervalSettings = {
    showInterval: 1 * 24 * 60 * 60 * 1000,
    showsLimit: 3,
    showsLimitResetDelay: 60 * 24 * 60 * 60 * 1000,
}

export const tryJdk8PerfModalMethod = getOpenModalMethod({
    modalId: "popup-get-jdk-8-perf",
    modalGroup: "popup-get-jdk-perf",
    modalDelay: 800,
    modalIntervalSettings: intervalSettings,
    onOpen: () => {
        sendGaEvent({
            'event': 'custom_event',
            'event_category': 'forms',
            'event_action': 'pop_up_window_liberica_perf',
            'event_label': 'links_on_downloads_page'
        })
    }
})

export const tryJdk11PerfModalMethod = getOpenModalMethod({
    modalId: "popup-get-jdk-11-perf",
    modalGroup: "popup-get-jdk-perf",
    modalDelay: 800,
    modalIntervalSettings: intervalSettings,
    onOpen: () => {
        sendGaEvent({
            'event': 'custom_event',
            'event_category': 'forms',
            'event_action': 'pop_up_window_liberica_perf',
            'event_label': 'links_on_downloads_page'
        })
    }
})

export const tryJdkPerfModalAfterDownloadMethod = getOpenModalMethod({
    modalId: Boolean(getFlag("downloaded-jdk-8")) ? "popup-get-jdk-8-perf" : "popup-get-jdk-11-perf",
    modalGroup: "popup-get-jdk-perf",
    modalDelay: 7000,
    modalIntervalSettings: intervalSettings,
    allowedToOpen: () => {
        return Boolean(getFlag("downloaded-jdk-11") || getFlag("downloaded-jdk-8"))
    },
    onOpen: () => {
        sendGaEvent({
            'event': 'custom_event',
            'event_category': 'forms',
            'event_action': 'pop_up_window_liberica_perf',
            'event_label': 'links_on_downloads_page'
        })
    }
})

export const webinarModalMethod = getOpenModalMethod({
    modalId: "popup-register-to-webinar-modal",
    modalDelay: 5000,
    modalIntervalSettings: {
        showInterval: 1 * 24 * 60 * 60 * 1000,
        showsLimit: 10,
    },
    allowedToOpen: () => {
        return Boolean(getFlag("downloaded-jdk-11") || getFlag("downloaded-jdk-8"))
    },
    onOpen: () => {
        sendGaEvent({
            'event': 'custom_event',
            'event_category': 'forms',
            'event_action': 'pop_up_window_register_to_webinar',
            'event_label': 'popup_show'
        })
    }
})

export const wrongDomainModalMethod = getOpenModalMethod({
    modalId: "wrong-domain",
    modalDelay: 1000,
    allowedToOpen: () => {
        const loc = window.location.hostname;
        return !loc.endsWith(atob("YmVsbC1zdy5jb20=")) && !(loc === "localhost")
    },
    modalIntervalSettings: {
        showsLimit: 0,
        showInterval: 0
    }
})
